export default {
	data: () => ({
		langRo: {
			links: {
				home: 'Acasă',
				shop: 'Magazin online',
				specialist: "Specialiști"
			},
			title: 'Magazin online',	
			filtreTitle: 'FILTRARE PRODUSE',
			brand: 'Brand',
			price: 'Preț',
			categorie: 'Categorie',
			sorted: 'Sortare',
			result: 'rezultate',
			btnAdd: 'Adaugă în coș',
			loadPage: 'Mai încarcă'
		}
	})
}








