export default {
	data: () => ({
		langEng: {
			links: {
				home: 'Home',
				shop: 'Shop',
				specialist: "Specialists"
			},
			title: 'Shop',
			filtreTitle: 'Product filter',
			brand: 'Brand',
			price: 'Price',
			categorie: 'Category',
			sorted: 'Range',
			result: 'results',
			btnAdd: 'Add to list',
			loadPage: 'Load more'
		}
	})
}


