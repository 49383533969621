export default {
	data: () => ({
		langRu: {
			links: {
				home: 'Домой',
				shop: 'Магазин',
				specialist: 'Специалисты'
			},
			title: 'Магазин',	
			filtreTitle: 'Фильтр продуктов',
			brand: 'Бренд',
			price: 'Цена',
			categorie: 'Категория',
			sorted: 'Ассортимент',
			result: 'результатов',
			btnAdd: 'Добавить в корзину',
			loadPage: 'Загрузи больше'
		}
	})
}